'use client'

import { useMemo } from 'react'
import NextLink from 'next/link'
import { motion } from 'framer-motion'
import { getPathLocale } from '@/utils/locale'
import useDisableScroll from '@/hooks/use-disable-scroll'
import { Icon } from '../icon'
import { Hamburger } from '../hamburger'
import { CigarBar } from '../cigar-bar'
import HeaderNav from './header.nav'
import HeaderShareBar from './header.share-bar'
import { header } from './header.variants'
import useHeader, { useHeaderProps } from './use-header'

export type HeaderProps = useHeaderProps

const Header = (props: HeaderProps) => {
  const { menu, cigarBar, locale, moisesStudio } = props

  const { menuRef, hidden, opened, onHandleMenu, blur, showShareBar } =
    useHeader()

  const styles = useMemo(() => header({ opened, blur }), [opened, blur])

  useDisableScroll(opened, menuRef)
  
  const siteMenu = menu.slice(0, menu.length - 3)
  const studioMenu = menu.slice(menu.length - 3, menu.length)

  return (
    <>
      <CigarBar className={styles.cigarbar()} data={cigarBar} />
      <motion.header
        ref={menuRef}
        className={styles.header()}
        variants={{
          visible: { y: 0 },
          hidden: { y: -80 }
        }}
        animate={hidden ? 'hidden' : 'visible'}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
      >
        <div className={styles.bar()}>
          <NextLink
            href={getPathLocale(locale)}
            title="Moises AI"
            locale={locale}
            className={styles.brandLink()}
          >
            <Icon name="brand" className={styles.brand()} />
          </NextLink>
          <HeaderNav
            styles={styles}
            siteMenu={siteMenu}
            studioMenu={studioMenu}
            studioLink={moisesStudio}
          />
          <Hamburger
            className="lg:hidden"
            active={opened}
            ariaControls="header-nav"
            toggle={() => onHandleMenu(!opened)}
          />
        </div>
      </motion.header>
      {showShareBar && <HeaderShareBar />}
    </>
  )
}

export default Header
